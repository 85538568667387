import axios from 'axios'

const BASE_URL = process.env.VUE_APP_API_URL
const noAuthRedirect = '/login'
// const noAuthRedirect = process.env.VUE_APP_APP_URL + 'login'

const axiosDefaults = {
  // withCredentials: true,
}

//shorten delete, js reserved
const del = async (endpoint = '', options = {}) => {
  const axiosEndpoint = BASE_URL + endpoint
  const axiosOptions = { ...axiosDefaults, ...options }
  return new Promise(async (res, rej) => {
    try {
      const resp = await axios.delete(axiosEndpoint, axiosOptions)
      res(resp.data)
    } catch (err) {
      console.log('error from axios.get')
      if (err?.response?.status === 401) {
        if (window?.location?.pathname !== noAuthRedirect) window.location.assign(noAuthRedirect)
      }
      rej(err)
    }
  })
}

const get = async (endpoint = '', options = {}, withBaseUrl = true) => {
  const axiosEndpoint = withBaseUrl ? BASE_URL + endpoint : endpoint
  const axiosOptions = { ...axiosDefaults, ...options }
  return new Promise(async (res, rej) => {
    try {
      const resp = await axios.get(axiosEndpoint, axiosOptions)
      res(resp.data)
    } catch (err) {
      console.log('error from axios.get')
      if (err?.response?.status === 401) {
        if (window?.location?.pathname !== noAuthRedirect) window.location.assign(noAuthRedirect)
      }
      rej(err)
    }
  })
}

//todo: use this
const isHttpError = statusCode => {
  const acceptedStatusCodes = [200, 304]
  return !acceptedStatusCodes.includes(statusCode)
}

const post = async (endpoint = '', data = {}, options = {}, withBaseUrl = true) => {
  const axiosEndpoint = withBaseUrl ? BASE_URL + endpoint : endpoint
  const axiosOptions = { ...axiosDefaults, ...options }
  const postData = typeof data === 'string' ? data : JSON.stringify(data)
  //set headers
  return new Promise(async (res, rej) => {
    try {
      const resp = await axios.post(axiosEndpoint, postData, axiosOptions)
      res(resp.data)
    } catch (err) {
      console.log('error from axios.post')
      rej(err)
    }
  })
}

export { del, get, post }
