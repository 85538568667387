<script>
  export default {
    name: 'nav-breadcrumbs',
    props: {
      show: {
        type: Boolean,
        default: () => false
      }
    },
    data: () => ({}),
    computed: {
      breadCrumbItems() {
        const routeOptions = {
          home: {
            icon: 'mdi-home',
            text: 'Home'
          }
          // kids: {
          //   text: 'Kids Corner'
          // }
        }
        let breadCrumbs = []
        let path = '/'
        let text = ''
        const cleanUrl = this.$route.fullPath.replaceAll('//', '/').split('?')
        const urlSegments = cleanUrl[0].split('/')
        //dont remove trailing slash here, breadcrumb styling relies on exact match
        // if (urlSegments?.length > 1 && urlSegments[urlSegments.length - 1] === "")
        //   urlSegments.pop();
        urlSegments.forEach((pathItem, idx) => {
          //add '/' for each path except for before 1st and after last segment
          path += `${urlSegments.length > 2 && idx > 1 && idx <= urlSegments.length - 1 ? '/' : ''}${pathItem}`
          text = idx === 0 ? 'Home' : pathItem
          // text = pathItem === '' ? 'Home' : pathItem
          if ((idx === urlSegments.length - 1 && pathItem === '') || pathItem?.includes('#')) {
            return
          }

          breadCrumbs.push({
            text: routeOptions[text.toLowerCase()] ? routeOptions[text.toLowerCase()]['text'] : text,
            href: path,
            icon: routeOptions[text.toLowerCase()] ? routeOptions[text.toLowerCase()].icon : null
          })
        })
        return breadCrumbs
      }
    },
    methods: {},
    created() {}
  }
</script>

<template>
  <v-breadcrumbs v-if="show" :items="breadCrumbItems" active-class="breadCrumbActive" color="secondary" class="d-flex align-center secondary--text font-weight-bold px-0">
    <template v-slot:divider>
      <v-icon color="secondary" class="px-0">mdi-chevron-right</v-icon>
    </template>
    <template v-slot:item="props">
      <router-link exact :to="props.item.href" class="breadCrumbs secondary--text font-weight-bold" :style="props?.item?.isFinal ? { 'text-decoration': 'underline' } : ''">
        <v-icon v-if="props.item.icon" color="secondary" small class="">{{ props.item.icon }}</v-icon>

        {{ props.item.text.toUpperCase() }}</router-link
      >
    </template>
  </v-breadcrumbs>
</template>

<style scoped>
  .breadCrumbs {
    text-decoration: none;
  }
  ul a:last-child {
    text-decoration: underline;
  }

  >>> .v-breadcrumbs__divider {
    padding: 0 !important;
  }
</style>
