import { render, staticRenderFns } from "./alert-message.vue?vue&type=template&id=3fce2630&scoped=true&"
import script from "./alert-message.vue?vue&type=script&lang=js&"
export * from "./alert-message.vue?vue&type=script&lang=js&"
import style0 from "./alert-message.vue?vue&type=style&index=0&id=3fce2630&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fce2630",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
installComponents(component, {VAlert})
